import React from "react"
// import { Link } from "gatsby"

import UserLayout from "../../components/userLayout"
import SEO from "../../components/seo"
// import Image from "../../components/image"

export default () => (
  <UserLayout>
    <SEO title="D2i Technology - Aditya Sharma"  description="Experienced Business Developer with expertise in public administration. Having proven track record of effectively handling technical requirements, providing technical solutions, managing projects, and navigating government procurement processes such as Government-E-Market. Additionally, I possess strong skills in database management, networking, and web/mobile technologies. My experience also extends to fostering strong business and customer relationships."/>
    <div className="container box_1620">
      <div className="banner_inner">
        <div className="banner_content">
          <div className="media">
            <div className="d-flex">
              <img className="img-responsive" src="/assets/img/users/aditya_sharma_d2itechnology.jpg" alt="Aditya Sharma profile" />
            </div>
            <div className="media-body">
              <div className="aditya_sharma_d2itechnology_text">
                {/* <h6>Hello Everybody, i am</h6> */}
                <h3>Aditya Sharma</h3>
                <h4>Business Development Manager</h4>
                <p className="text-justify">Experienced Business Developer with expertise in public administration. Having proven track record of effectively handling technical requirements, providing technical solutions, managing projects, and navigating government procurement processes such as Government-E-Market. Additionally, I possess strong skills in database management, networking, and web/mobile technologies. My experience also extends to fostering strong business and customer relationships.</p>
                <ul className="list basic_info">
                  <li><a href="tel:+91 98995 79823"><i className='bx bxs-phone'></i> +91 98995 79823</a></li>
                  <li><a href="mailto:amit.d2itechnology@gmail.com"><i className='bx bxs-envelope'></i> aditya.sharma@d2itechnology.com</a></li>
                  <li><a target="_blank" rel="noreferrer" href="https://www.google.com/maps/place/Noida,+Uttar+Pradesh,+India/@28.5166817,77.2580444,11z/data=!3m1!4b1!4m5!3m4!1s0x390ce5a43173357b:0x37ffce30c87cc03f!8m2!3d28.5355161!4d77.3910265"><i className='bx bx-home' ></i> Noida, India</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </UserLayout>
)
